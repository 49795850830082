.kurumsal-container {
  margin-top: 6rem;
  background-color: #191919;
}

.kurumsal-container .bread-list hr {
  border-color: #6e6d70;
}

.kurumsal-container .bread-list ul {
  display: flex;
  margin: 1rem 6rem;
}

.kurumsal-container .bread-list ul li {
  margin: 0 1rem;
  font-weight: 500;
  color: #cfcfcf;
}

.kurumsal-container .bread-list ul li a {
  color: #cfcfcf;
}

.kurumsal-container .bread-list ul li a:hover {
  color: orange;
}

.kurumsal-container .bread-list ul .return-mainpage:hover {
  transform: rotateX(360deg);
  transition: var(--transition);
}

@media screen and (max-width: 768px) {
  .kurumsal-container .bread-list ul {
    margin: 1rem 3rem;
  }
}

@media screen and (max-width: 600px) {
  .kurumsal-container .bread-list ul {
    margin: 1rem 1rem;
  }

  .kurumsal-container .bread-list ul li {
    margin: 0 0.5rem;
  }
}

@media screen and (max-width: 375px) {
  .kurumsal-container .bread-list ul li {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 325px) {
  .kurumsal-container .bread-list ul li {
    margin: 0 0.35rem;
    font-size: 0.55rem;
  }
}
