#about{
    transition: var(--transition);  
    background-color: #2f2f2f;
}

.about-container{
    display: flex;
    justify-content: center;
    padding: 0.3rem 0.3rem;
}

.about-container h2{
    color: var(--color-white);
}

.about-container h3{
    color: #cfcfcf;
    margin: 0.7rem 0;
}

.about-container .col-container{
    width: 45%;
    margin: 0 1rem;     
}

.about-container .col-container.left{
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-container .col-container.right{
    animation: slideLeft 1.3s ease;
}

.about-container .col-container a img{
    width: 100%;
    height: 25rem;
    object-fit:contain;
    animation: slideRight 1.3s ease;
}

.about-container .col-container p{
    width: 100%;
    margin-bottom: 1.5rem;
    font-size: 0.8rem;
    color: #aaaaaaba;
}

.about-container .col-container hr{
    border: 0.13rem solid;
    color: #aaa;
    margin: 0 45rem 0 0;
}

@keyframes slideLeft{
    from{
        transform: translateX(70rem);
      }
      to{
        transform: none;
      }
}

@keyframes slideRight{
    from{
        transform: translateX(-70rem);
      }
      to{
        transform: none;
      }
}

@media screen and (max-width:1670px) {
    .about-container .col-container hr{
        margin: 0 38rem 0 0;
    }   
}

@media screen and (max-width:1440px) {
    .about-container .col-container hr{
        margin: 0 31rem 0 0;
    }   
}

@media screen and (max-width:1250px) {
    .about-container .col-container hr{
        margin: 0 26rem 0 0;
    }   
}

@media screen and (max-width:1080px) {
    .about-container{
        flex-direction: column;
        align-items: center;
    }

    .about-container .col-container.left{
        margin-bottom: 3rem;
    }

    .about-container .col-container.right{
        width: 90%;
    }

    .about-container .col-container hr{
        margin: 0 50rem 0 0;
    }
}

@media screen and (max-width:768px) {
    .about-container .col-container p{
        font-size: 0.7rem;
    }
    .about-container .col-container hr{
        margin: 0 35rem 0 0;
    }   

    .about-container .col-container a img{
        height: 16rem;
    }
}

@media screen and (max-width:600px) {
    .about-container .col-container p{
        font-size: 0.63rem;
    }

    .about-container .col-container hr{
        border: 0.1rem solid;
        margin: 0 18rem 0 0;
    }

    

    @keyframes slideLeft{
        from{
            transform: translateX(30rem);
          }
          to{
            transform: none;
          }
    }
    
    @keyframes slideRight{
        from{
            transform: translateX(-30rem);
          }
          to{
            transform: none;
          }
    }
}

@media screen and (max-width:380px) {
    .about-container .col-container hr{
        margin: 0 15rem 0 0;
    }

    .about-container .col-container.left{
        margin-bottom: 0;
    }
}
@media screen and (max-width:325px) {
    .about-container .col-container hr{
        margin: 0 13rem 0 0;
    }
}