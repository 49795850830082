#contact{
    background-color: #545454;
    transition: var(--transition);
}

.contact-container{
    display: grid;
    align-items: center;
    padding: 0.3rem 0.3rem;
}

.contact-container .row{
    width: 100%;
    display: flex;
}

.contact-container .row h2{ 
    color: #cfcfcf; 
}

.contact-container .row .col-left{
    width: 50%;
    margin: 0 2rem 0 1rem;
}

.contact-container .row .col-left h2{
    margin: 0 1rem;
}

.contact-container .row .col-left .form-group{
    border-radius: 2rem;
    border: 0;
    margin: 1rem 1rem;
    height: 2.7rem;
    display: block;
    font-size: 1rem;
    line-height: 1.1rem;
    width: 90%;
    padding: 1rem;
    background-color: #191919;
    font-family: 'Poppins', sans-serif;
    color: var(--color-white);
}

.contact-container .row .col-left .form-group::placeholder{
    color: #aaa;
}

.contact-container .row .col-left textarea{
    height: auto !important;
}

.contact-container .row .col-left .btn{
    margin: 1rem 1rem;
    background-color: #f09433b5;  
    color: whitesmoke;
    width: 90%;
    display: grid;
    align-items: center;
    font-weight:600;
    font-size: 0.9rem;
    letter-spacing: 0.3rem;
    height: 2.7rem;
    transition: all 125ms ease ;
    border-color: #545454;
}

.contact-container .row .col-left .btn:hover{
    transform: translateY(-0.1rem);
    border-color: #aaa;
}

.contact-container .row .col-right{
    width: 40%; 
    margin: 0 0 0 2rem;
}

.contact-container .row .col-right h4{
    margin: 1rem 0;
    color: #000000;
    font-weight: bold;
}

.contact-container .row .col-right ul li{
    display: flex;
    align-items: center;
    margin: 0.5rem 0 1rem 0; 
}

.contact-container .row .col-right ul li i{
    display: grid;
    margin-right: 0.5rem;
}

.contact-container .row .col-right ul li svg{
 color: #aaa ;
 height: 1.3rem;
 width: 1.3rem;
 text-align: center;
}

.contact-container .row .col-right ul li p{
    color: #191919;
    font-weight: 500;
}

.contact-container .row .col-right iframe{
    width: 100%;
    height: 15rem;
    border: 0;
}

@media screen and (max-width: 1080px) {

    .contact-container .row p{
        font-size: 0.8rem;
    }

    .contact-container .row .col-right ul li svg{
        width: 1.5rem;
        height: 1.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    .contact-container .row{
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
    }

    .contact-container .row .col-left, .contact-container .row .col-right{
        width: 90%;
        text-align: center;
        margin: 1rem 0;
    }

    .contact-container .row .col-left h2{
        margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 600px) {
    .contact-container{
        padding: 0;
    }

    .contact-container .row{
        padding: 0;
    }

    .contact-container .row p{
        font-size: 0.7rem;
        padding:0;
    }

    .contact-container .row .col-left, .contact-container .row .col-right{
        width: 90%;
        text-align:start;
        margin: 0.5rem;
    }

    .contact-container .row .col-left form{
        display: flex;
        flex-direction: column;
        align-items: center;
    }   

    .contact-container .row h2{
        margin: 0;
        text-align: center;
        font-size: 1.8rem;
    }

    .contact-container .row h4{
        text-align: center;
    }

    .contact-container .row .col-left .form-group{
        margin: 1rem 0;
        font-size: 0.8rem;
    }

    .contact-container .row .col-left .btn{
        margin: 1rem 0;
    } 
  }

  @media screen and (max-width: 375px) {
    .contact-container .row h2{
        font-size: 1.7rem;
    }
  }