nav {
  width: 100vw;
  height: 6rem;
  display: grid;
  place-items: center;
  background-color: #2f2f2f;
  position: fixed;
  opacity: 0.9;
  top: 0;
  left: 0;
  z-index: 3;
  transition: var(--transition);
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.nav-container .nav-logo {
  width: auto;
  height: 100%;
}

.nav-container .nav-logo a img {
  width: 4.5rem !important;
}

.nav-container .nav-links ul {
  display: flex;
  font-weight: 600;
  font-size: 1.1rem;
}

.nav-container .nav-links ul li {
  margin: 0 3.5rem;
  list-style: outside none none;
  position: relative;
  float: left;
}

.nav-container .nav-links ul li a:before,
.nav-container .nav-links ul li a:after {
  content: '';
  position: absolute;
  transition: all .35s ease;
  opacity: 0;
}

.nav-container .nav-links ul li a{
  display: flex;
  align-items: center;
}

.nav-container .nav-links ul li a:hover{
  color: #3DA35D;
  transform: translateY(-0.3rem); 
}

.nav-container .nav-links ul li .menu-dropdown{
  background: orange;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  display: block;
  min-width: 9rem;
  border-radius: 0 10% 0 10%;
}

.nav-container .nav-links ul li:hover > .menu-dropdown,
.nav-container .nav-links ul li:focus-within > .menu-dropdown,
.nav-container .nav-links ul li .menu-dropdown:hover,
.nav-container .nav-links ul li .menu-dropdown:focus{
  visibility: visible;
  opacity: 1;
  display: block; 
}

.nav-container .nav-links ul li .menu-dropdown li{
  width:  100%;
  margin: 0;
  padding: 1rem;
  border-radius: 0 10% 0 10%;
}

.nav-container .nav-links ul li .menu-dropdown li:hover{
  color: #3DA35D;
  background: #262626; 
}

.nav-container .nav-links ul li .menu-dropdown li a{
  font-weight: 500;
  font-size: 0.9rem;
  width: max-content;
}

.nav-container .nav-links ul li .menu-dropdown li a:hover{
  transform: translateX(0.5rem);
  transition: var(--transition);
}

.nav-container .nav-links .mobile-nav-link{
  display: none;
}

.nav-container .nav-social ul {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.nav-container .nav-social ul li svg {
  width: 2.1rem;
  height: 2.1rem;
  margin: 0 1rem;
  color: var(--color-white);
  transition: var(--transition);
  text-align: center;  
}

.nav-container .nav-social ul .instagram svg:hover{
  color: var(--color-black)!important;
}

.nav-container .nav-social ul .facebook svg:hover{
  color: #3b5998;
  background-color: var(--color-white);
}

@media screen and (max-width: 1440px) {
  .nav-container .nav-links ul li{
      margin: 0 1.5rem;
    }
}

@media screen and (max-width: 1080px) {
  .nav-container .nav-links ul li{
    margin: 0 2rem;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 768px) {
  .nav-container .nav-logo a img{
    width: 4rem!important;
  }

  .nav-container .nav-links ul li{
    margin: 0 1rem;
    font-size: 0.7rem;
  }

  .nav-container .nav-links ul li .menu-dropdown li a{
    font-size: 0.7rem;
  }

  .nav-container .nav-social ul li svg{
    width: 1.5rem;
    height: 1.6rem;
  }
}

@media screen and (max-width: 600px) {
  .nav-container .nav-logo a img{
    width: 3rem!important;
  }

  .nav-container .nav-links ul li{
    margin: 0 0.3rem;
    font-size: 0.6rem;
  }

  .nav-container .nav-links ul li .menu-dropdown li a{
    font-size: 0.6rem;
  }

  .nav-container .nav-social ul li svg{
    margin: 0 0.3rem;
    width: 1.3rem;
  }
}

@media screen and (max-width: 375px) {  
  .nav-container .nav-logo a img{
    width: 2.5rem!important;
  }

  .nav-container .nav-links ul li{
    margin: 0 0.30rem;
    font-size: 0.55rem;
  }

  .nav-container .nav-links ul li .menu-dropdown li a{
    font-size: 0.5rem;
  }

  .nav-container .nav-social ul li svg{
    width: 1.2rem;
  }
}

@media screen and (max-width: 325px) {  
  .nav-container .nav-logo a img{
    width: 2.1rem!important;
  }

  .nav-container .nav-links ul li{
    margin: 0 0.15rem;
    font-size: 0.5rem;
  }

  .nav-container .nav-links ul li .menu-dropdown li a{
    font-size: 0.5rem;
  }

  .nav-container .nav-social ul li svg{
    width: 1rem;
  }
}