#misvis{
    transition: var(--transition);
    background-color: #2f2f2f;
}

.misvis-container p{
    text-align:  justify;
    font-size: 0.9rem;
    color: #cbcbcbe0;
}

.misvis-container h2{
    color: var(--color-white);
    text-align: center;
    margin-bottom: 0.5rem;
}

.misvis-container .misvis{
    margin: 3rem 5rem 5rem 5rem;
    display: flex;
    padding: 0.5rem 0;
    justify-content: space-between;
}

.misvis-container .misvis hr{
    border: 0.13rem solid #aaa;
    margin: 0 35% 1.5rem 35%;
}

.misvis-container .misvis .col-container{
    margin: 0rem 5rem 1rem 5rem;
    width: 50%;
}

.misvis-container .misvis .col-container.left{
    animation: fromLeftMisvis 1.5s ease;
}

.misvis-container .misvis .col-container.right{
    animation: fromRightMisvis 1.5s ease;
}

.misvis-container .amac{
    margin: 7.5rem 18rem 6rem 18rem;
    animation: fromDownMisvis 1.5s ease;    
}

.misvis-container .amac hr{
    border: 0.13rem solid #aaa;
    margin: 0 35% 1.5rem 35%;
}

.misvis-container .amac ul li{
    list-style-type: disc;
    color: var(--color-white); 
}

@keyframes fromLeftMisvis{
    from{
        transform: translateX(-50rem);
    }
    to{
        transform: none;
    }
}

@keyframes fromRightMisvis{
    from{
        transform: translateX(50rem);
    }
    to{
        transform: none;
    }
}

@keyframes fromDownMisvis{
    from{
        transform: translateY(8rem);
    }
    to{
        transform: none;
    }
}

@media screen and (max-width: 1625px) {
    .misvis-container .misvis hr{
        margin: 0 30% 0.5rem 30%;
    }

    .misvis-container .amac hr{
        margin: 0 30% 0.5rem 30%;
    }  
}

@media screen and (max-width: 1080px) {  
    .misvis-container .misvis{
        margin: 2rem 1rem;
    }
    
    .misvis-container .misvis hr{
        margin: 0 20% 0.5rem 20%;
    }
    
    .misvis-container .amac{
        margin: 5rem 10rem;
    }

    .misvis-container .amac hr{
        margin: 0 25% 0.5rem 25%;
    }  
}

@media screen and (max-width: 768px) {
    .misvis-container .misvis{
        margin: 1rem;
    }
    
    .misvis-container .misvis .col-container{
        margin: 0 3rem;
    }

    .misvis-container .misvis .col-container.left{
        animation: fromLeftMisvis 1.3s ease;
    }
    
    .misvis-container .misvis .col-container.right{
        animation: fromRightMisvis 1.3s ease;
    }

    .misvis-container h2{
        font-size: 1.7rem;
    }
    
    .misvis-container p{
        font-size: 0.7rem;
    }
    .misvis-container .misvis hr{
        margin: 0 15% 1rem 15%;
    }
    
    .misvis-container .amac{
        margin: 3rem 5rem;
        animation: fromDownMisvis 1.2s ease;
    }

    .misvis-container .amac hr{
        margin: 0 20% 1rem 20%;
    }

    @keyframes fromLeftMisvis{
        from{
            transform: translateX(-30rem);
        }
        to{
            transform: none;
        }
    }
    
    @keyframes fromRightMisvis{
        from{
            transform: translateX(30rem);
        }
        to{
            transform: none;
        }
    }
    
    @keyframes fromDownMisvis{
        from{
            transform: translateY(5rem);
        }
        to{
            transform: none;
        }
    }
}

@media screen and (max-width: 600px) {
    .misvis-container h2{
        font-size: 1.25rem;
    }

    .misvis-container p{
        font-size: 0.58rem;
    }    

    .misvis-container .misvis hr{
        border: 0.1rem solid #aaa;
        margin: 0 10% 1rem 10%;
    }

    .misvis-container .misvis .col-container{
        margin: 0 1rem 1rem 1rem;
    }

    .misvis-container .amac{
        margin: 1.5rem 5rem;
    }

    .misvis-container .amac hr{
        margin: 0 5% 0.5rem 5%;
        border: 0.1rem solid #aaa;
    }  
}

@media screen and (max-width: 375px) {
    .misvis-container h2{
        font-size: 1rem;
    }

    .misvis-container p{
        font-size: 0.53rem;
    }  

    .misvis-container .misvis hr{
        margin: 0 3% 1rem 3%;
    }

    .misvis-container .misvis .col-container{
        margin: 0 0.75rem 1rem 0.75rem;
    }

    .misvis-container .amac{
        margin: 1rem 3rem;
    }

    .misvis-container .amac hr{
        margin: 0 10% 0.5rem 10%;
    }  
}