#BoM {
  transition: var(--transition);
  background-color: #2f2f2f;
}

.BoM-container {
  display: flex;
  justify-content: space-between;
  margin: 2.5rem 0;
}

.BoM-container h1 {
  color: var(--color-white);
  margin: 1rem 3rem 3rem 3rem;
  font-size: 3.5rem;
  letter-spacing: 0.3rem;
}

.BoM-container p {
  color: #aaa;
  margin: 1rem 3rem;
  font-size: 1rem;
  line-height: 2rem;
}

.BoM-container .col {
  width: 50%;
  height: fit-content;
  animation: fromLeftBoM 2s ease;
}

.BoM-container .col.right {
  display: flex;
  justify-content: center;
  border-left: 1px solid #6e6d70;
}

/* gradient gray */

.BoM-container .col img {
  width: 26%;
  background: radial-gradient(
        circle at 100% 100%,
        #ffffff 0,
        #ffffff 2px,
        transparent 2px
      )
      0% 0%/8px 8px no-repeat,
    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 2px, transparent 2px)
      100% 0%/8px 8px no-repeat,
    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 2px, transparent 2px)
      0% 100%/8px 8px no-repeat,
    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 2px, transparent 2px) 100%
      100%/8px 8px no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 12px)
      calc(100% - 16px) no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 16px)
      calc(100% - 12px) no-repeat,
    linear-gradient(
      90deg,
      #d0caca 0%,
      rgb(190, 188, 188) 25%,
      rgba(153, 152, 152, 0.603) 75%,
      rgb(156, 155, 155) 100%
    );
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
}

@keyframes fromLeftBoM {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@keyframes fromRightBoM {
  from {
    transform: translateX(100rem);
  }
  to {
    transform: none;
  }
}

@media screen and (max-width: 1600px) {
  .BoM-container p {
    font-size: 0.9rem;
  }

  .BoM-container .col img {
    width: 35%;
  }
}

@media screen and (max-width: 1080px) {
  .BoM-container h1 {
    font-size: 3rem;
  }

  .BoM-container .col img {
    width: 53%;
  }
}

@media screen and (max-width: 768px) {
  .BoM-container p {
    margin-right: 3rem;
  }

  .BoM-container {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .BoM-container .col {
    width: 100%;
  }

  .BoM-container .col.col.right {
    justify-content: center;
    margin-bottom: 3rem;
  }

  .BoM-container .col img {
    width: 35%;
  }
}

@media screen and (max-width: 600px) {
  .BoM-container {
    margin: 2rem 0;
  }

  .BoM-container h1 {
    font-size: 2.5rem;
    margin: 0 2rem 1rem 2rem;
    letter-spacing: 0.1rem;
  }

  .BoM-container p {
    margin: 0.5rem 2rem;
    line-height: 1.8;
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 375px) {
  .BoM-container h1 {
    font-size: 1.5rem;
    margin: 0 2rem 1rem 2rem;
    letter-spacing: 0.1rem;
  }

  .BoM-container p {
    margin: 0.5rem 2rem;
    line-height: 1.8;
    font-size: 0.7rem;
  }
}
