.footer{
    background-color: #191919;
    transition: var(--transition);
}

.footer h3, .footer h4{
    color: var(--color-white)!important;
}

.footer p, .footer li{
    color: var(--color-white);
    text-decoration: solid;
    transition: all 0.2s ease-in-out;
}

.footer hr{
    margin: 0.5rem 0 1rem 0;
    border: 1px solid white;
}

.footer-container{
    padding: 2rem 2rem 0 0.1rem;
    display: flex;
    flex-direction: column;
}

.footer-container .footer-content{
    display: flex;
    justify-content: space-between;
    height: 100%;
    margin: 0 12.5rem;
}

.footer-container .footer-content .footer-logo{
    width:max-content;
    height: 100%;   
}

.footer-container .footer-content .footer-logo a{
    display: grid;
    justify-content: center;
    align-items: center;
}

.footer-container .footer-content .footer-logo a img{
    width: 8rem;
    height: 8rem;
    margin-bottom: 1rem;
}

.footer-container .footer-content .footer-logo .phone{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
}

.footer-container .footer-content .footer-logo .phone i{
    display: grid;   
}

.footer-container .footer-content .footer-logo svg{
    margin-right: 0.5rem;
    text-align: center;
}

.footer-container .footer-content .footer-fast-access hr{
    width: 50%;
}

.footer-container .footer-content .footer-fast-access ul li:hover{
    transform: translatex(0.5rem);
}

.footer-container .footer-content .footer-fast-access ul li a:hover{
    color: #3DA35D;
}

.footer-container .footer-content .footer-social hr{
    width: 40%;
}

.footer-container .footer-content .footer-social p{
    margin-bottom: 1rem;
}

.footer-container .footer-content .footer-social ul{
    display: flex;
}

.footer-container .footer-content .footer-social ul li{
    width: 50%;
}

.footer-container .footer-content .footer-social ul li svg{
    width: 100%;
    height: 2rem;
    transition: var(--transition);
}

.footer-container .footer-content .footer-social ul .instagram svg:hover{
    color: var(--color-black)!important;
}

.footer .copyright{
    padding: 0.5rem 0;
    margin-top: 1.5rem;
    text-align: center; 
    color: var(--color-white);
}

@media screen and (max-width: 1440px) {
    .footer-container .footer-content{
        margin: 0 5rem;
    }
}

@media screen and (max-width: 1080px) {
    .footer-container .footer-content .footer-social hr{
        width: 30%;
    }
}

@media screen and (max-width: 768px) {
    .footer-container .footer-content{
        margin: 0 3rem;
    }

    .footer-container .footer-content .footer-logo a img{
        width: 6rem;
        height: 6rem;
    }
}

@media screen and (max-width: 600px) {
    .footer-container{
        padding: 2rem 0.3rem 0 0.3rem;
    }

    .footer-container .footer-content{
        font-size: 0.7rem;
        margin: 0 1rem;
    }

    .footer-container .footer-content .footer-logo a img{
        width: 4rem;
        height: 4rem;
        margin-bottom: 0.5rem;
    }

    .footer-container .footer-content .footer-logo .phone h4{
        font-size: 0.6rem;
    }

    .footer-container .footer-content .footer-fast-access{
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: column;
        font-size: .55rem;
        max-width: 34%;
    }

    .footer-container .footer-content .footer-fast-access hr{
        margin: 0.3rem 0 0.5rem 0;
        width: 75%;
    }

    .footer-container .footer-content .footer-social{
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 30%;
        font-size: .55rem;
    }

    .footer-container .footer-content .footer-social hr{
        margin: 0.3rem 0 0.5rem 0;
        width: 75%;
    }

    .footer-container .footer-content .footer-social ul li{
        margin: 0 0.3rem;
    }

    .footer-container .footer-content .footer-social ul li svg{
        height: 1.5rem;
        width: 1.3rem;
    }

    .footer .copyright{
        margin-top: 0.5rem;
    }
}