#projects {
  margin-top: 6rem;
  transition: var(--transition);
  background-color: #191919;
}

.projects-container {
  display: grid;
  position: relative;
  width: 100%;
  justify-content: center;
}

.projects-container h1,
.projects-container h3,
.projects-container h4 {
  text-align: center;
}

.projects-container .head-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projects-container .head-row .head-col h1 {
  color: #d2d1df;
  font-size: 3.5rem;
  text-align: start;
  margin: 0.5rem 0 0.5rem 5rem;
  animation: translateHead 1.5s;
}

.projects-container .head-row .head-col img{
    height: 7.5rem;
    margin: 0.5rem 5rem 0.5rem 0;
    animation: translateHeadRight 1.5s;
}

.projects-container .projects-row {
  margin-top: 2rem;
}

.projects-container .projects-row .slide-show-container {
  max-width: 100%;
  position: relative;
  color: #15191f;
  
}

.projects-container .projects-row .slide-show-container img{
  height: 42rem;
  width: 1920px;
  opacity: 0.45; 
}

.projects-container .projects-row .slide-show-container .text{
  position: absolute;
  top: 5rem;
  left: 10rem;
  display: flex;
  color: var(--color-white);
  font-size: 1rem;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 35%;
  background-color: #191919;
  opacity: 0.7;
  z-index: 2;
  transition: var(--transition);
  border-bottom: 1rem solid orange;
  animation: fadetext 1s;
}

.projects-container .projects-row .slide-show-container .text h3{
  color: var(--color-white);
  padding-left: 1rem;
  border-left: 1rem solid orange;
  opacity: 1;
  font-size: 1.5rem;
  text-align: start;
  margin: 2.5rem 2.5rem 1rem 2.5rem;
  font-weight: 1000;
  animation: translateY 2s;
}

.projects-container .projects-row .slide-show-container .text p{
  opacity: 1;
  margin: 1rem 3rem 0 3rem;
  animation: translateX 2s ease;
}

.projects-container .projects-row .slide-show-container .detail-button {
  justify-content: center;
  width: 100%;
  display: flex;
  margin: 2rem 0 1rem 0;
}

.projects-container .projects-row .slide-show-container .detail-button button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 5rem;
  border-radius: 1.5rem;
  border: none;
  background: #6e6d70;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5),
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
  color: #dfdedf;
  font-size: 1rem;
}

.projects-container
  .projects-row
  .slide-show-container
  .detail-button
  button:hover {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
  transition: 0.34s;
  transform: translateY(-0.3rem);
  cursor: pointer;
}

/* Slide css*/
.projects-container .mySlides {
  display: none;
}

.projects-container .activeSlide {
  display: block;
  animation: fade 1.5s ease-in-out;
}

.projects-container .prev,
.projects-container .next {
  cursor: pointer;
  position: absolute;
  top: 45%;
  width: auto;
  padding: 1rem;
  margin-top: -1.8rem;
  color: #6e6d70;
  font-weight: bold;
  font-size: 1.75rem;
  transition: var(--transition);
  border-radius: 0 0.2rem 0.2rem 0;
  user-select: none;
  background-color: #191919;
}

.projects-container .next {
  right: 0;
  border-radius: 0.2rem 0 0 0.2rem;
}

.projects-container .prev:hover,
.projects-container .next:hover {
  background-color: #dfdedf;
}


@keyframes fade {
  from{
    opacity: 0.3;
  }
  to{
    opacity: 1;
  }  
}

@keyframes fadetext{
  from{
    opacity: 0;
  }
  to{
    opacity: 0.7;
  }
}

@keyframes translateY{
    from{
      transform: translateY(-3rem);
    }
    to{
      transform: none;
    }
}

@keyframes translateX{
  from{
    transform: translateX(-3rem);
  }
  to{
    transform: none;
  }
}

@keyframes translateHead{
  from{
    transform: translateX(18rem);
  }
  to{
    transform: none;
  }
}

@keyframes translateHeadRight{
  from{
    transform: translateX(-18rem);
  }
  to{
    transform: none;
  }
}

@media screen and (max-width: 1440px) {
  .projects-container .projects-row .slide-show-container img {
    height: 30rem;
  }
}

@media screen and (max-width: 1080px) {
  .projects-container .head-row .head-col h1{
    font-size: 2.8rem;
  }

  .projects-container .head-row .head-col img{
    height: 6.1rem;
  }

  .projects-container .projects-row .slide-show-container .text{
    top: 4rem;
    left: 5rem;
  }

  .projects-container .projects-row .slide-show-container .text h3{
    font-size: 1.1rem;
    border-left: 0.5rem orange solid;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
  }

  .projects-container .projects-row .slide-show-container .text p{
    font-size: 0.9rem;
  }

  .projects-container .prev,.projects-container .next{
    padding: 0.9rem;
    font-size: 1.3rem;
  }

  @keyframes translateHead{
    from{
      transform: translateX(10rem);
    }
    to{
      transform: none;
    }
  }
  
  @keyframes translateHeadRight{
    from{
      transform: translateX(-10rem);
    }
    to{
      transform: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .projects-container .head-row .head-col h1{
    font-size: 2rem;
    margin-left: 3.5rem;
  }

  .projects-container .head-row .head-col img{
    height: 5rem;
  }

  .projects-container .projects-row .slide-show-container img {
    height: 24rem;
  }

  .projects-container .projects-row .slide-show-container .text{
    top: 3.5rem;
    left: 4.5rem;
    width: 50%;
  }

  .projects-container .projects-row .slide-show-container .text h3{
    font-size: 0.9rem;
    border-left: 0.5rem orange solid;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
  }

  .projects-container .projects-row .slide-show-container .text p{
    font-size: 0.7rem;
    margin-top: 0;
  }

  .projects-container .projects-row .slide-show-container .detail-button button{
    padding: 0.9rem 2.5rem;
  }
}

@media screen and (max-width: 600px) {
  @keyframes translateHead{
    from{
      transform: translateX(3rem);
    }
    to{
      transform: none;
    }
  }
  
  @keyframes translateHeadRight{
    from{
      transform: translateX(-3rem);
    }
    to{
      transform: none;
    }
  }

  .projects-container .projects-row{
    margin-top: 1rem;
  }

  .projects-container .head-row .head-col h1{
    font-size: 1.2rem;
    margin-left: 2.5rem;
  }

  .projects-container .head-row .head-col img{
    height: 3.5rem;
    margin-right: 2.5rem;
  }

  .projects-container .projects-row .slide-show-container img {
    height: 18rem;
  }

  .projects-container .projects-row .slide-show-container .text{
    top: 1rem;
    left: 3rem;
    width: 70%;
    height: 30%;
    border-bottom: 0.5rem orange solid;
  }

  .projects-container .projects-row .slide-show-container .text h3{
    font-size: 0.7rem;
    border-left: 0.4rem orange solid;
    margin: 1rem;
  }

  .projects-container .projects-row .slide-show-container .text p{
    font-size: 0.6rem;
    margin: 0 1rem;
  }

  .projects-container .prev,
  .projects-container .next{
    font-size: 0.8rem;
    top: 41%;
    padding: 0.8rem;
  }

  .projects-container .projects-row .slide-show-container .detail-button {
    justify-content: center !important;
  }

  .projects-container
    .projects-row
    .slide-show-container
    .detail-button
    button {
    font-size: 0.8rem;
  }
}

@media screen and (max-width:375px) {
  .projects-container .projects-row .slide-show-container img {
    height: 15rem;
  }

  .projects-container .projects-row .slide-show-container .text h3{
    border-left: 0.3rem orange solid;
    padding-left: 0.3rem;
    margin-bottom: 0.7rem;
  }
}

@media screen and (max-width:325px) {
  .projects-container .head-row .head-col h1{
    font-size: 1rem;
    margin-left: 1.3rem;
  }

  .projects-container .head-row .head-col img{
    height: 3rem;
    margin-right: 1.3rem;
  }

  .projects-container .projects-row .slide-show-container .text h3{
    margin: 1rem 0.5rem 0.7rem 0.5rem;
  }

  .projects-container .projects-row .slide-show-container .text p{
    margin: 0 0.5rem 0.7rem 0.5rem;
  }
}