* {
  margin: 0;
  padding: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  --primary-hue: 53;
  --black-lightness: 15%;
  --dark-lightness: 44%;
  --light-lightness: 94%;
  --white-lightness: 100%;
  --color-primary: hsl(var(--primary-hue), 89%, 41%);
  --color-white: hsl(0, 5%, var(--white-lightness));
  --color-light: hsl(var(--primary-hue), 5%, var(--light-lightness));
  --color-black: hsl(var(--primary-hue), 5%, var(--black-lightness));
  --color-dark: hsl(var(--primary-hue), 5%, var(--dark-lightness));

  /* GENERAL CONTAINER VARIABLES */
  --container-width-lg: 85%;
  --container-width-md: 90%;

  /* GENERAL TRANSITION VARIABLES */
  --transition: all 500ms ease;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 6rem;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  line-height: 1.6;
}

/* GENERAL CONTAINER CLASS */
.container {
  /*width: var(--container-width-lg);*/
  margin-inline: auto;
  max-width: 1920px;
}

/* GENERAL SECTION STYLES */
section {
  background: var(--color-white);
  transition: var(--transition);
  padding: 2rem 0;
}

section > h2 {
  text-align: center;
}

section > p {
  text-align: center;
  margin: 0.5rem auto 4rem;
  width: 40%;
}

a {
  color: var(--color-white);
  transition: var(--transition);
}

/* GENERAL HEADING STYLES */
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  color: var(--color-black);
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.9rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.4rem;
}

h5{
  font-size: 1rem;
}

h6{
    font-size: 0.8rem;
}

/* GENERAL IMAGES STYLES*/
img {
  display: block;
  max-width: 100%;
}

/* GENERAL BUTTON STYLES */
.btn {
  border: 2px solid;
  border-radius: 0.8rem;
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-00.1rem);
  background-color: transparent;
  border-color: var(--color-dark);
  color: var(--color-black);
  font-weight: bold;
  height: 2.6rem;
}

/* SOCIAL MEDIA HOVER STYLES */
.instagram svg:hover{
  background: #f09433; 
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  color: var(--color-white);
  border-radius: 10%;
}

.facebook svg:hover{
  color: #3b5998;
  background-color: var(--color-white);
  border-radius: 10%;
}

/* MEDIA QUERIES (for tablets) */



@media screen and (max-width: 1024px) {

  h1 {
    font-size: 2rem;
  }

  h2{
    font-size: 1.8rem;
  }

  h3{
    font-size: 1.2rem;
  }

  h4{
    font-size: 1rem;
  }

  h5{
    font-size: 0.9rem;
  }
  
  h6{
      font-size: 0.8rem;
  }

  body{
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 768px) {

  h1 {
    font-size: 2rem;
  }

  h2{
    font-size: 1.8rem;
  }

  h3{
    font-size: 1.1rem;
  }

  h4{
    font-size: 1rem;
  }

  h5{
    font-size: 0.9rem;
  }
  
  h6{
      font-size: 0.8rem;
  }

  body{
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 600px) {
  section{
    padding: 1rem 0;
  }

  h1 {
    font-size: 1.3rem;
  }
  h2 {
    font-size: 1.1rem;
  }

  h3 {
    font-size: 0.9rem;
  }

  h4 {
    font-size: 0.8rem;
  }

  h5{
    font-size: 0.8rem;
  }
  
  h6{
      font-size: 0.8rem;
  }

  .btn {
    padding: 0.6rem 1.5rem;
  }

  section p {
    font-size: 0.66rem;
    line-height: 0.9rem;
  }
}